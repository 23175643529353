//Function for toggling mobile menu
const mobileMenu = document.getElementById("mobile-menu");
const navIcon = document.getElementById("nav-icon");

const mobileMenuToggle = document.getElementById("mobile-menu-toggle");

function toggleMobileMenu() {
  navIcon.classList.toggle("is-active");
  mobileMenu.classList.toggle("active");
  mobileMenuToggle.blur();
}
function toggleMobileMenuKeyboard(event) {
  if(event.code === "Enter" || event.code === "Space"){
    navIcon.classList.toggle("is-active");
    mobileMenu.classList.toggle("active");
  }
}

mobileMenuToggle.addEventListener("click", toggleMobileMenu);
mobileMenuToggle.addEventListener("keydown",toggleMobileMenuKeyboard );

//Adding arrow-button to menu items that has a sub menu
var menuArrowElement = document.createElement("button");
menuArrowElement.className = "menu-arrow";
var menuItemElements = document.querySelectorAll(".menu-item-has-children");
menuItemElements.forEach((menuItemElement) => menuItemElement.prepend(menuArrowElement.cloneNode(true)));

//Making arrows in menu clickable to fold out sub menu
const allMenuItemsWithArrow = document.querySelectorAll(".menu-arrow");

function addClassToParent(event) {
  const clickedElement = event.target;
  const listItem = clickedElement.parentNode;

  if (listItem) {
    if (listItem.classList.contains("is-active")) {
      listItem.classList.remove("is-active");
    } else {
      listItem.classList.add("is-active");
    }
  }
}

allMenuItemsWithArrow.forEach((menuItem) => {
  menuItem.addEventListener("click", addClassToParent);
});
