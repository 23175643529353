
class AnimationInteractiveExercise {
  constructor(videoElement) {
    this.videoRef = videoElement;
    this.timeoutRef = { current: null };
    this.viewedRef = { current: [] };
    this.IDLE_SEQUENCE = 1;
    this.errorMargin = 0.1;
    this.parts = this.getTimes();
    this.playing = false;
    this.activeTimer = false;
    this.sequences = ["show", "idle"];
    this.id = -1;
    this.currentSequence = this.IDLE_SEQUENCE;
    this.addOne = 1;
    this.current03 = 0.3;
    this.x1000 = 1000;
    this.zero = 0;
  }

  nextSequence() {
    if (this.currentSequence === this.SHOW_SEQUENCE) {
      this.currentSequence = (this.currentSequence + this.addOne) % this.sequences.length;
      this.currentPart = this.parts[this.id][this.sequences[this.currentSequence]];
    } else {
      this.currentSequence = (this.currentSequence + this.addOne) % this.sequences.length;
      this.currentPart = this.parts[this.id][this.sequences[this.currentSequence]];
      this.videoRef.currentTime = this.currentPart.from;
    }
  }

  endOfSequence() {
    if (this.currentSequence === this.IDLE_SEQUENCE) {
      this.videoRef.currentTime = this.currentPart.from;
    } else {
      clearTimeout(this.timeoutRef.current);
      this.activeTimer = false;
      this.nextSequence();
    }
  }

  onVideoCanPlayThrough() {
    if (!this.playing) {
      this.playing = true;
      this.videoRef.currentTime = this.currentPart.from;
      this.videoRef.style.display = "block";
      this.videoRef.play();
    }
  }

  onVideoTimeUpdate() {
    if (this.videoRef.currentTime + this.errorMargin >= this.currentPart.to && !this.activeTimer) {
      this.endOfSequence();
    }
    if (this.currentPart.to - this.videoRef.currentTime < this.current03) {
      this.activeTimer = true;
      this.timeoutRef.current = setTimeout(() => {
        this.endOfSequence();
      }, (this.videoRef.currentTime - this.currentPart.to) * this.x1000);
    }
  }

  onVideoClick() {
    if (this.currentSequence === this.IDLE_SEQUENCE) {
      if (this.viewedRef.current.length === this.parts.length) {
        this.viewedRef.current = [];
      }
      do {
        this.id = Math.floor(Math.random() * this.parts.length);
      } while (this.viewedRef.current.indexOf(this.id) >= this.zero);

      this.viewedRef.current.push(this.id);
      this.nextSequence();
    }
  }

  setup() {
    if (this.videoRef) {
      this.videoRef.addEventListener("click", this.onVideoClick.bind(this));
      this.videoRef.addEventListener("timeupdate", this.onVideoTimeUpdate.bind(this));
      this.videoRef.addEventListener("canplaythrough", this.onVideoCanPlayThrough.bind(this));
    }
  }
}

class Loppa extends AnimationInteractiveExercise {
  constructor(videoElement) {
    super(videoElement);
    this.SHOW_SEQUENCE = 0;
    this.start = { idle: { from: 16, to: 17.21 } };
    this.currentPart = this.start[this.sequences[this.currentSequence]];
    this.setup();
  }

  getTimes() {
    return [
      {
        // 0
        show: { from: 0, to: 2.83 },
        idle: { from: 2.81, to: 3.79 },
      },
      {
        // 1
        show: { from: 4, to: 6.8 },
        idle: { from: 6.81, to: 7.79 },
      },
      {
        // 3
        show: { from: 8, to: 10.8 },
        idle: { from: 10.81, to: 11.79 },
      },
      {
        // 4
        show: { from: 12, to: 14.8 },
        idle: { from: 14.81, to: 15.79 },
      },
    ];
  }
}

class Cube extends AnimationInteractiveExercise {
  constructor(videoElement) {
    super(videoElement);
    this.start = { idle: { from: 0, to: 1.04 } };
    this.currentPart = this.start[this.sequences[this.currentSequence]];
    this.setup();
  }

  getTimes() {
    return [
      {
        // 0
        idle: { from: 1.09, to: 2.12 },
        show: { from: 6.49, to: 7.36 },
      },
      {
        // 1
        idle: { from: 2.17, to: 3.2 },
        show: { from: 7.41, to: 8.28 },
      },
      {
        // 3
        idle: { from: 3.25, to: 4.28 },
        show: { from: 8.33, to: 9.2 },
      },
      {
        // 4
        idle: { from: 4.33, to: 5.36 },
        show: { from: 9.25, to: 10.12 },
      },
      {
        // 4
        idle: { from: 5.41, to: 6.44 },
        show: { from: 10.17, to: 11.04 },
      },
    ];
  }
}

class Wheel extends AnimationInteractiveExercise {
  constructor(videoElement) {
    super(videoElement);
    this.SPIN_SEQUENCE = 1;
    this.IDLE_SEQUENCE = 3;
    this.sequences = ["start", "spin", "end", "idle"];
    this.spin = { from: 6.8, to: 8.08 };
    this.emojiId = 0;
    this.currentSequence = 3;
    this.currentPart = this.parts[this.emojiId][this.sequences[this.currentSequence]];
    this.setup();
  }

  nextSequence() {
    this.currentSequence = (this.currentSequence + this.addOne) % this.sequences.length;
    if (this.currentSequence === this.SPIN_SEQUENCE) {
      this.currentPart = this.spin;
    } else {
      this.currentPart = this.parts[this.emojiId][this.sequences[this.currentSequence]];
    }
    this.videoRef.currentTime = this.currentPart.from;
  }

  endOfSequence() {
    if (this.currentSequence === this.SPIN_SEQUENCE || this.currentSequence === this.IDLE_SEQUENCE) {
      this.videoRef.currentTime = this.currentPart.from;
    } else {
      clearTimeout(this.timeoutRef.current);
      this.activeTimer = false;
      this.nextSequence();
    }
  }

  onVideoClick() {
    if (this.currentSequence === this.SPIN_SEQUENCE) {
      if (this.viewedRef.current.length === this.parts.length) {
        this.viewedRef.current = [];
      }
      do {
        this.emojiId = Math.floor(Math.random() * this.parts.length);
      } while (this.viewedRef.current.indexOf(this.emojiId) >= this.zero);

      this.viewedRef.current.push(this.emojiId);

      this.nextSequence();
    }
    if (this.currentSequence === this.IDLE_SEQUENCE) {
      this.nextSequence();
    }
  }

  getTimes() {
    return [
      {
        // 0 glad
        start: { from: 0, to: 0.64 },
        end: { from: 8.12, to: 9.8 },
        idle: { from: 25.33, to: 26.36 },
      },
      {
        // 1 förvånad
        start: { from: 0.69, to: 1.32 },
        end: { from: 9.85, to: 11.51 },
        idle: { from: 26.41, to: 27.44 },
      },
      {
        // 2 grön
        start: { from: 1.37, to: 2 },
        end: { from: 11.57, to: 13.23 },
        idle: { from: 27.49, to: 28.53 },
      },
      {
        // 3 gråter
        start: { from: 2.05, to: 2.69 },
        end: { from: 13.29, to: 14.91 },
        idle: { from: 28.57, to: 29.6 },
      },
      {
        // 4 hjärtögon
        start: { from: 2.73, to: 3.37 },
        end: { from: 15.01, to: 16.67 },
        idle: { from: 29.65, to: 30.69 },
      },
      {
        // 5 tår
        start: { from: 3.41, to: 4.05 },
        end: { from: 16.73, to: 18.41 },
        idle: { from: 30.73, to: 31.77 },
      },
      {
        // 6 arg
        start: { from: 4.09, to: 4.73 },
        end: { from: 18.45, to: 20.12 },
        idle: { from: 31.81, to: 32.85 },
      },
      {
        // 7 skrattgråt
        start: { from: 4.77, to: 5.41 },
        end: { from: 20.17, to: 21.84 },
        idle: { from: 32.89, to: 33.92 },
      },
      {
        // 8 spyr
        start: { from: 5.45, to: 6.09 },
        end: { from: 21.89, to: 23.51 },
        idle: { from: 33.97, to: 35.01 },
      },
      {
        // 9 röd arg
        start: { from: 6.13, to: 6.77 },
        end: { from: 23.6, to: 25.29 },
        idle: { from: 35.05, to: 36.08 },
      },
    ];
  }
}
class LoppaBase extends AnimationInteractiveExercise {
  constructor(videoElement) {
    super(videoElement);
    this.SHOW_SEQUENCE = 0;
    this.start = { idle: { from: 16, to: 17.21 } };
    this.currentPart = this.start[this.sequences[this.currentSequence]];
    this.setup();
  }

  getTimes() {
    return [
      {
        // 0
        show: { from: 0, to: 2.83 },
        idle: { from: 2.81, to: 3.79 },
      },
      {
        // 1
        show: { from: 4, to: 6.8 },
        idle: { from: 6.81, to: 7.79 },
      },
      {
        // 3
        show: { from: 8, to: 10.8 },
        idle: { from: 10.81, to: 11.79 },
      },
      {
        // 4
        show: { from: 12, to: 14.8 },
        idle: { from: 14.81, to: 15.79 },
      },
    ];
  }
}

class LoppaSoomaali extends LoppaBase {
  constructor(videoElement) {
    super(videoElement);
  }
}

class LoppaArabic extends LoppaBase {
  constructor(videoElement) {
    super(videoElement);
  }
}

class LoppaEnglish extends LoppaBase {
  constructor(videoElement) {
    super(videoElement);
  }
}

window.Cube = Cube;
window.Loppa = Loppa;
window.Wheel = Wheel;
window.LoppaSoomaali = LoppaSoomaali;
window.LoppaArabic = LoppaArabic;
window.LoppaEnglish = LoppaEnglish;