const manuallyFillForm = document.getElementById("manual-filling-form");
const manuallyFillButton = document.getElementById("manual-fill-btn");

function expandManuallyFillSection() {
  const manuallyFillFormInner = document.getElementById("manual-filling-form-inner");
  const formHeight = manuallyFillFormInner.offsetHeight + "px";
  manuallyFillForm.style.height = formHeight;
  manuallyFillButton.style.height = "0px";

  manuallyFillForm.addEventListener(
    "transitionend",
    function () {
      manuallyFillForm.style.height = "auto";
    },
    { once: true }
  );
}

function expandOnClick() {
  manuallyFillButton.addEventListener("click", function () {
    expandManuallyFillSection();
  });
}

if (manuallyFillForm) {
  expandOnClick();
}

window.populateMonthlyForm = (data) => {
  var ssn = data.ssn ? data.ssn.replace(/^(.{6,8})(.{4})$/, "$1-$2") : "";
  document.getElementById("social-security-number").value = ssn;
  document.getElementById("firstname").value = data.firstName || "";
  document.getElementById("lastname").value = data.lastName || "";
  document.getElementById("address").value = data.streetAddress || "";
  document.getElementById("zipcode").value = data.zipCode || "";
  document.getElementById("city").value = data.city || "";
  document.getElementById("bank").value = data.bankName || "";
  document.getElementById("clearingnumber").value = data.swedishClearingNumber || "";
  document.getElementById("accountnumber").value = data.swedishAccountNumber || "";
  expandManuallyFillSection();
};
