const magicnumber = 1000;

function monthlyDonationCheckbox() {
  document.addEventListener("DOMContentLoaded", function () {
    const checkbox = document.getElementById("monthlyDonation");
    const privateFormAccountNumber = document.getElementById("form-content-box-private-account-number");
    const monthlyDonationFields = document.getElementsByClassName("monthly-donation-fields");
    const privateDonationFields = document.getElementsByClassName("private-donation-fields");
    const paymentTypeRadioButtons = document.getElementsByClassName("payment-container");
    const monthlyDonationFieldsArray = Array.from(monthlyDonationFields);
    const paymentTypeRadioButtonsArray = Array.from(paymentTypeRadioButtons);
    const privateDonationFieldsArray = Array.from(privateDonationFields);
    const monthlyDonationHidden = document.getElementById("monthlyDonationHidden");

    function showMonthlyDonationFields(item) {
      item.querySelector("input").required = checkbox.checked;
      item.querySelector("input").disabled = !checkbox.checked;
      if (checkbox.checked) {
        item.classList.add("monthly-donation-fields-display");
        if (privateFormAccountNumber) {
          privateFormAccountNumber.classList.add("display");
        }
      } else {
        item.classList.remove("monthly-donation-fields-display");
        if (privateFormAccountNumber) {
          privateFormAccountNumber.classList.remove("display");
        }
      }
    }
    function hidePrivateDonationFields(item) {
      item.querySelector("input").required = !checkbox.checked;
      item.querySelector("input").disabled = checkbox.checked;
      if (checkbox.checked) {
        item.classList.add("private-donation-fields-hide");
      } else {
        item.classList.remove("private-donation-fields-hide");
      }
    }
    function showPaymentOptions(item) {
      item.querySelector("input").disabled = checkbox.checked;
      if (checkbox.checked) {
        item.classList.add("payment-container-hide");
      } else {
        item.classList.remove("payment-container-hide");
      }
    }

    function showMonthlyDividers() {
      const elements = document.getElementsByClassName("form-divider-monthly");
      for (let divider = 0; divider < elements.length; divider++) {
        const element = elements[divider];
        if (checkbox.checked) {
          element.classList.add("show-divider");
        } else {
          element.classList.remove("show-divider");
        }
      }
    }

    if (checkbox) {
      monthlyDonationHidden.value = checkbox.checked;
      checkbox.addEventListener("change", function () {
        monthlyDonationFieldsArray.forEach(showMonthlyDonationFields);
        paymentTypeRadioButtonsArray.forEach(showPaymentOptions);
        if (privateDonationFieldsArray) {
          privateDonationFieldsArray.forEach(hidePrivateDonationFields);
        }
        showMonthlyDividers();
        monthlyDonationHidden.value = checkbox.checked;
      });
    }

    setTimeout(() => {
      monthlyDonationFieldsArray.forEach(showMonthlyDonationFields);
      paymentTypeRadioButtonsArray.forEach(showPaymentOptions);
      if (privateDonationFieldsArray) {
        privateDonationFieldsArray.forEach(hidePrivateDonationFields);
      }
      if (monthlyDonationHidden) {
        monthlyDonationHidden.value = checkbox.checked;
      }
    }, magicnumber);
  });
}

function privateOrCompanyPaymentMemorialGift() {
  const radiobuttonPrivate = document.querySelector('input[value="private-payment"]');
  const radiobuttonCompany = document.querySelector('input[value="company-payment"]');

  const sendToMemorialPrivate = document.getElementById("sendToMemorialPrivate");
  const sendToMemorialCompany = document.getElementById("sendToMemorialCompany");
  const companyFields = document.querySelectorAll(".company-payment input");
  const privateField = document.querySelector(".private-payment input");

  const handleRadioButtonChange = () => {
    const isPrivateChecked = radiobuttonPrivate.checked;
    sendToMemorialCompany.disabled = isPrivateChecked;
    sendToMemorialPrivate.disabled = !isPrivateChecked;
    companyFields.forEach((field) => {
      field.disabled = isPrivateChecked;
    });
    privateField.disabled = !isPrivateChecked;
  };

  const deliveryType = document.querySelectorAll("input[name='deliveryType']");
  const recieverEmailInput = document.querySelector(".reciever-email input");
  const recieverPrinterInput = document.querySelectorAll(".reciever-printed input");

  const handleDeliveryTypeChange = (singleDeliveryType) => {
    var deliveryTypeValue = singleDeliveryType.target.value;
    recieverPrinterInput.forEach((field) => {
      field.disabled = true;
    });
    if (deliveryTypeValue === "PostCustomer") {
      recieverEmailInput.disabled = true;
    } else if (deliveryTypeValue === "Email") {
      recieverEmailInput.disabled = false;
    } else {
      recieverPrinterInput.forEach((field) => {
        field.disabled = false;
      });
      recieverEmailInput.disabled = true;
    }
  };
  setTimeout(() => {
    deliveryType.forEach((singleDeliveryType) => {
      if (singleDeliveryType.checked) {
        var deliveryTypeValue = singleDeliveryType.value;
        if (deliveryTypeValue === "PostCustomer") {
          recieverEmailInput.disabled = true;
        } else if (deliveryTypeValue === "Email") {
          recieverEmailInput.disabled = false;
        } else {
          recieverPrinterInput.forEach((field) => {
            field.disabled = false;
          });
          recieverEmailInput.disabled = true;
        }
      }
    });
  }, magicnumber);
  deliveryType.forEach((singleDeliveryType) => {
    singleDeliveryType.addEventListener("change", handleDeliveryTypeChange);
  });
  radiobuttonPrivate.addEventListener("change", handleRadioButtonChange);
  radiobuttonCompany.addEventListener("change", handleRadioButtonChange);
}

function handleGreetingClassNames() {
  document.addEventListener("DOMContentLoaded", function () {
    const preChosenGreetingRadio = document.querySelector('input[value="pre-chosen-greeting"]');
    const personalGreetingRadio = document.querySelector('input[value="personal-greeting"]');

    function updateGreetingContentClass() {
      if (preChosenGreetingRadio.checked) {
        document.getElementById("pre-chosen-greetings").classList.add("show");
        document.getElementById("pre-chosen-greetings").classList.remove("hide");
        document.getElementById("write-own-greeting-label").classList.add("hide");
        document.getElementById("write-own-greeting-label").classList.remove("show");
      } else if (personalGreetingRadio.checked) {
        document.getElementById("pre-chosen-greetings").classList.add("hide");
        document.getElementById("pre-chosen-greetings").classList.remove("show");
        document.getElementById("write-own-greeting-label").classList.add("show");
        document.getElementById("write-own-greeting-label").classList.remove("hide");
      }
    }
    preChosenGreetingRadio.addEventListener("change", updateGreetingContentClass);
    personalGreetingRadio.addEventListener("change", updateGreetingContentClass);

    setTimeout(() => {
      updateGreetingContentClass();
    }, magicnumber);
  });
}

function handlePrivateOrCompanyPayment() {
  document.addEventListener("DOMContentLoaded", function () {
    const privatePaymentRadio = document.querySelector('input[value="private-payment"]');
    const companyPaymentRadio = document.querySelector('input[value="company-payment"]');

    function updatePrivateOrCompanyPaymentClass() {
      if (privatePaymentRadio.checked) {
        document.getElementById("payment-content-fields").classList.add("show-private-person");
        document.getElementById("payment-content-fields").classList.remove("show-company");
      } else if (companyPaymentRadio.checked) {
        document.getElementById("payment-content-fields").classList.add("show-company");
        document.getElementById("payment-content-fields").classList.remove("show-private-person");
      }
    }

    const radiobuttonPrivate = document.querySelector('input[value="private-payment"]');
    const sendToMemorialPrivate = document.getElementById("sendToMemorialPrivate");
    const sendToMemorialCompany = document.getElementById("sendToMemorialCompany");
    const companyFields = document.querySelectorAll(".company-payment input");
    const privateField = document.querySelector(".private-payment input");

    const radioButtonSelectedOnLoad = () => {
      const isPrivateChecked = radiobuttonPrivate.checked;
      sendToMemorialCompany.disabled = isPrivateChecked;
      sendToMemorialPrivate.disabled = !isPrivateChecked;
      companyFields.forEach((field) => {
        field.disabled = isPrivateChecked;
      });
      privateField.disabled = !isPrivateChecked;
    };

    setTimeout(() => {
      updatePrivateOrCompanyPaymentClass();
      radioButtonSelectedOnLoad();
    }, magicnumber);

    privatePaymentRadio.addEventListener("change", updatePrivateOrCompanyPaymentClass);
    companyPaymentRadio.addEventListener("change", updatePrivateOrCompanyPaymentClass);
  });
}

function handleReceiver() {
  document.addEventListener("DOMContentLoaded", function () {
    const emailRadio = document.querySelector('input[value="Email"]');
    const postCustomerRadio = document.querySelector('input[value="PostCustomer"]');
    const postRelativeRadio = document.querySelector('input[value="PostRelative"]');
    const postUndertakerRadio = document.querySelector('input[value="PostUndertaker"]');
    const churchRadio = document.querySelector('input[value="Church"]');

    function updateDeliveryTypeClass() {
      if (emailRadio.checked) {
        document.getElementById("receiver-content-fields").classList.add("show-email");
        document.getElementById("receiver-content-fields").classList.remove("hide-all");
        document.getElementById("receiver-content-fields").classList.remove("show-name-and-address");
      } else if (postCustomerRadio.checked) {
        document.getElementById("receiver-content-fields").classList.add("hide-all");
        document.getElementById("receiver-content-fields").classList.remove("show-email");
        document.getElementById("receiver-content-fields").classList.remove("show-name-and-address");
      } else {
        document.getElementById("receiver-content-fields").classList.add("show-name-and-address");
        document.getElementById("receiver-content-fields").classList.remove("show-email");
        document.getElementById("receiver-content-fields").classList.remove("hide-all");
      }
    }
    setTimeout(() => {
      updateDeliveryTypeClass();
    }, magicnumber);

    emailRadio.addEventListener("change", updateDeliveryTypeClass);
    postCustomerRadio.addEventListener("change", updateDeliveryTypeClass);
    postRelativeRadio.addEventListener("change", updateDeliveryTypeClass);
    postUndertakerRadio.addEventListener("change", updateDeliveryTypeClass);
    churchRadio.addEventListener("change", updateDeliveryTypeClass);
  });
}

function setFormAmount() {
  let radioButtons = document.getElementsByName("amount");
  let ownAmount = document.getElementById("ownAmount");
  let hiddenAmount = document.getElementById("hiddenAmount");

  function unsetOwnAmount(item) {
    item.addEventListener("click", function () {
      ownAmount.required = false;
      ownAmount.value = "";
      hiddenAmount.value = item.value;
    });
  }
  radioButtons.forEach(unsetOwnAmount);

  function unsetRadioButtons(item) {
    item.checked = false;
  }

  ownAmount.addEventListener("input", function () {
    radioButtons.forEach(unsetRadioButtons);
    hiddenAmount.value = ownAmount.value;
    ownAmount.required = true;
  });
}

function quickSwish() {
  var popupElement;
  var quickSwishWrapper = document.getElementById("quick-swish-wrapper");
  var quickSwishBtn = document.getElementById("quick-swish");
  var quickSwishBtnMobile = document.getElementById("quick-swish-mobile");
  var closeSwishPopupBtn = document.getElementById("close-swish-popup");
  var ownAmountBtn = document.getElementById("own-amount-btn");
  var ulElement = document.getElementById('donate-amount');
  var donateAmountButtons = ulElement.querySelectorAll('input[type="radio"]');
  var hiddenAmount = document.getElementById("hiddenAmount");
  var ownAmount = document.getElementById("ownAmount");

  setFormAmount();

  var removePopup = () => {
    document.querySelectorAll(".swish-popup").forEach( (oldPopup) => {
      document.getElementById("donation-form-quick-swish").reset();
      hiddenAmount.value = 0;
      ownAmount.required = false;
      document.getElementById("own-amount-wrapper").style.display = "none";
      oldPopup.parentNode.removeChild(oldPopup);
    });
  };

  var createPopup = () => {
    ownAmount.required = false;
    quickSwishWrapper.style.display = "block";
    removePopup();
    popupElement = document.createElement("div");
    popupElement.classList = "swish-popup";
    popupElement.appendChild(quickSwishWrapper);
    document.body.prepend(popupElement);
    
  };

  var toggleOwnAmountField = (event) => {
    var donateAmountButton = event.target;
    if (donateAmountButton.id === "amount-100") {
      document.getElementById("own-amount-wrapper").style.display = "none";
      ownAmount.required = false;
    } else if (donateAmountButton.id === "amount-300") {
      document.getElementById("own-amount-wrapper").style.display = "none";
      ownAmount.required = false;
    } else if (donateAmountButton.id === "own-amount-btn") {
      ownAmount.required = true;
      document.getElementById("own-amount-wrapper").style.display = "block";
    }
  };

  if(quickSwishBtn){
    quickSwishBtn.addEventListener("click", createPopup);
    quickSwishBtnMobile.addEventListener("click", createPopup);
  }
  if(closeSwishPopupBtn){
    closeSwishPopupBtn.addEventListener("click", removePopup);
  }
  if(ownAmountBtn){
    donateAmountButtons.forEach(function(donateAmountButton) {
      donateAmountButton.addEventListener('click', toggleOwnAmountField);
    });
  }
}

let privateDonationForm = document.getElementById("donation-form-private");

if (privateDonationForm) {
  monthlyDonationCheckbox();
  setFormAmount();
}

let monthlyDonationForm = document.getElementById("donation-form-monthly-account-number");

if (monthlyDonationForm) {
  monthlyDonationCheckbox();
  setFormAmount();
}

let companyDonationForm = document.getElementById("donation-form-company");

if (companyDonationForm) {
  setFormAmount();
}

let memorialGiftForm = document.getElementById("memorial_gift_payment");

if (memorialGiftForm) {
  handleGreetingClassNames();
  privateOrCompanyPaymentMemorialGift();
  handlePrivateOrCompanyPayment();
  handleReceiver();
  setFormAmount();
}

if (document.getElementById("donation-form-quick-swish")) {
  quickSwish();
}