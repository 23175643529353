document.addEventListener("DOMContentLoaded", function () {
  const zero = 0;
  const paddingLeft = 50;
  const scrollLeft = document.getElementById("scroll-left");
  const scrollRight = document.getElementById("scroll-right");
  const chaptersContainer = document.querySelector("#education-chapters-wrapper");

  function scrollLeftHandler() {
    chaptersContainer.scrollBy({
      left: -150,
      behavior: "smooth",
    });
  }

  function scrollRightHandler() {
    chaptersContainer.scrollBy({
      left: 150,
      behavior: "smooth",
    });
  }
  if (scrollLeft) {
    scrollLeft.addEventListener("click", scrollLeftHandler);
  }
  if (scrollRight) {
    scrollRight.addEventListener("click", scrollRightHandler);
  }
  if (chaptersContainer) {
    chaptersContainer.addEventListener("scroll", function () {
      scrollLeft.style.display = chaptersContainer.scrollLeft > zero ? "block" : "none";
      scrollRight.style.display =
        chaptersContainer.scrollLeft < chaptersContainer.scrollWidth - chaptersContainer.clientWidth ? "block" : "none";
    });
  }

  var allChapters = document.getElementById("education-chapters");
  if (allChapters) {
    var originalWhiteSpace = allChapters.style.whiteSpace;
    allChapters.style.whiteSpace = "normal";
    var chaptersWidth = allChapters.scrollWidth;
    allChapters.style.whiteSpace = originalWhiteSpace;

    var contentSection = document.getElementById("education-chapters-wrapper");
    var contentSectionWidth = contentSection.offsetWidth;

    var allSingleChapters = document.querySelectorAll(".chapter");
    if (chaptersWidth > contentSectionWidth) {
      allChapters.classList.add("align-left");
      document.getElementById("scroll-right").style.display = "block";

      allSingleChapters.forEach((chapter) => {
        if (chapter.classList.contains("active")) {
          var distanceFromStart = chapter.offsetLeft;
          var chapterWidth = chapter.offsetWidth;
          var distanceToChapterEnd = distanceFromStart + chapterWidth;

          var positionLeft = distanceToChapterEnd - contentSectionWidth;
          if (distanceToChapterEnd > contentSectionWidth) {
            chaptersContainer.scrollBy({
              left: positionLeft + paddingLeft,
            });
          }
        }
      });
    }
  }
  //for mobile select-field
  var popupSelection = document.getElementById("education-chapters-mobile-selection");
  var popupContent = document.getElementById("education-chapters-mobile-content");
  var popupContentInner = document.getElementById("education-chapters-mobile-inner");
  var educationChaptersMobile = document.getElementById("education-chapters-mobile");
  var educationChaptersMobileClose = document.getElementById("education-chapters-mobile-close");
  var displayTimeOut = 500;
  if (educationChaptersMobile) {
    educationChaptersMobile.addEventListener("click", function () {
      popupSelection.classList.add("display");
      var mobileSelectedChapterInnerHeight = popupContentInner.offsetHeight;
      document.body.classList.add("popup-open");
      popupContent.style.height = mobileSelectedChapterInnerHeight + "px";
    });
  }
  function closePopup() {
    popupContent.style.height = "0px";
    setTimeout(function () {
      popupSelection.classList.remove("display");
      document.body.classList.remove("popup-open");
    }, displayTimeOut);
  }
  if (popupContent) {
    educationChaptersMobileClose.addEventListener("click", function () {
      closePopup();
    });
  }
  if (popupSelection) {
    popupSelection.addEventListener("click", function (event) {
      if (!popupContent.contains(event.target)) {
        closePopup();
      }
    });
  }
});
