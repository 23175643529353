window.wigglyLines = (function() {
  var exports = {};

  function fuzz(position, wiggle){
    return position + Math.random()*wiggle - wiggle/2;
  }

  function getLinePartDelta(x0, x1, tFactor) {
    /*eslint no-magic-numbers: 0*/
    return (x0 * (1-tFactor) + x1 * tFactor);
  }

  function drawWigglyLine(ctx, p0, p1, wiggle, shadow = false, shadowOffset = 0){
    ctx.moveTo(p0.x, p0.y);
    var length = Math.sqrt((p1.x-p0.x) * (p1.x-p0.x)  +  (p1.y-p0.y) * (p1.y-p0.y));
    var steps = Math.ceil(Math.max(length/(shadow ? 4 : 40), 6));
    var overlap = (p0.x > p1.x || p0.y > p1.y) ? -0.1 : 0.1;

    for(var index=0 ; index <= steps; index++){
      var t1 = (index+0.2)/steps;
      var t0 = t1-0.5/steps;
      var xt0 = getLinePartDelta(p0.x, p1.x, t0);
      var yt0 = getLinePartDelta(p0.y, p1.y, t0);
      var xt1 = getLinePartDelta(p0.x, p1.x, t1);
      var yt1 = getLinePartDelta(p0.y, p1.y, t1);
      if(shadow) {
        xt1 = fuzz(xt1, wiggle * 0.3) + shadowOffset * 0.2;
        yt1 = fuzz(yt1, wiggle * 0.3) + shadowOffset * 0.2;
        ctx.quadraticCurveTo(fuzz(xt0, wiggle*0.3) + shadowOffset, fuzz(yt0, wiggle*0.3) + shadowOffset, xt1, yt1);
      } else {
        ctx.quadraticCurveTo(fuzz(xt0, wiggle), fuzz(yt0, wiggle), xt1+overlap, yt1+overlap);
      }
      ctx.moveTo(xt1, yt1);
    }
  }

  function setCanvasSizeToDOMSize(canvas) {
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;
    return {width: canvas.width, height: canvas.height};
  }

  function drawRectPath(canvas, config, size) {
    const ctx = canvas.getContext("2d");
    ctx.strokeStyle = config.color;
    ctx.lineWidth = config.lineWidth;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    var cornerTopLeft = {x: config.margin, y: config.margin};
    var cornerBottomLeft = {x: config.margin, y: size.height - config.margin};
    var cornerBottomRight = {x: size.width - config.margin, y: size.height - config.margin};
    var cornerTopRight = {x: size.width - config.margin, y: config.margin};

    ctx.beginPath();
    drawWigglyLine(ctx, cornerTopLeft, cornerBottomLeft, config.wiggle, false, 0);
    drawWigglyLine(ctx, cornerBottomLeft, cornerBottomRight, config.wiggle, config.shadow, config.shadowOffset);
    drawWigglyLine(ctx, cornerBottomRight, cornerTopRight, config.wiggle, config.shadow, config.shadowOffset);
    drawWigglyLine(ctx, cornerTopRight, cornerTopLeft, config.wiggle, false, 0);
    ctx.stroke();
    if(config.shadow) {
      ctx.beginPath();
      console.log(cornerBottomLeft);
      drawWigglyLine(ctx, cornerBottomLeft, cornerBottomRight, config.wiggle, false, 0);
      drawWigglyLine(ctx, cornerBottomRight, cornerTopRight, config.wiggle, false, 0);
      ctx.stroke();
    }
  }

  function drawLinePath(canvas, config, size) {
    const ctx = canvas.getContext("2d");
    ctx.strokeStyle = config.color;
    ctx.lineWidth = config.lineWidth;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    var cornerLeft = {x: config.margin, y: config.margin};
    var cornerRight = {x: size.width - config.margin, y: config.margin};

    ctx.beginPath();
    drawWigglyLine(ctx, cornerLeft, cornerRight, config.wiggle, false, 0);
    ctx.stroke();
  }

  exports.drawRect = function(canvasSelector, config) {
    config.margin = Math.max(config.margin, config.wiggle / 2);
    const canvas = document.querySelector(canvasSelector);
    canvas.classList.add("wiggly-box-canvas");
    canvas.setAttribute("aria-hidden", "true");
    canvas.parentNode.classList.add("wiggly-menu-container");
    const menuElement = canvas.parentNode.querySelector("*:not(.wiggly-box-canvas)");
    if(!menuElement) {
      console.error("Wiggly canvas must be followed by the menu");
      return;
    }
    menuElement.classList.add("wiggly-menu");

    window.addEventListener("resize", () => {
      var size = setCanvasSizeToDOMSize(canvas);
      drawRectPath(canvas, config, size);
    });
    requestAnimationFrame(() => {
      var size = setCanvasSizeToDOMSize(canvas);
      drawRectPath(canvas, config, size);
    });
  };

  exports.drawLine = function(canvasSelector, config) {
    const canvas = document.querySelector(canvasSelector);
    canvas.height = config.margin * 2;
    canvas.classList.add("wiggly-line-canvas");
    canvas.setAttribute("aria-hidden", "true");
    window.addEventListener("resize", () => {
      var size = setCanvasSizeToDOMSize(canvas);
      drawLinePath(canvas, config, size);
    });
    requestAnimationFrame(() => {
      var size = setCanvasSizeToDOMSize(canvas);
      drawLinePath(canvas, config, size);
    });
  };

  exports.defaultConfig = function() {
    return {color: "#234d96", margin: 5, wiggle: 10, sectionSize: 30, lineWidth: 1.8, shadow: false};
  };
  exports.boxshadowConfig = function() {
    return {color: "#234d96", margin: 10, wiggle: 5, sectionSize: 30, lineWidth: 1.8, shadow: true, shadowOffset: 12};
  };
  exports.columnConfig = function() {
    return {color: "#4165A4", margin: 5, wiggle: 10, sectionSize: 30, lineWidth: 1.8, shadow: false};
  };

  return exports;
})();
