function forgotPassword() {
  let forgotPasswordLink = document.getElementById("forgot-password-link");

  if (forgotPasswordLink) {
    forgotPasswordLink.addEventListener("click", function () {
      const loginEmailValue = document.getElementById("login-email").value;
      let forgotPasswordContent = document.getElementById("form-content-forgot-password");
      let loginContent = document.getElementById("form-content-login");
      document.getElementById("forgot-password-email").value = loginEmailValue;
      forgotPasswordContent.classList.add("display");
      loginContent.classList.remove("display");
    });
  }

  let newPassword = document.getElementById("new-password");
  let newPasswordRepeat = document.getElementById("new-password-repeat");

  const validatePasswords = () => {
    if (newPassword.value && newPasswordRepeat.value) {
      newPasswordRepeat.setCustomValidity(newPassword.value !== newPasswordRepeat.value ? "Ogiltigt fält." : "");
    }
  };

  if(newPasswordRepeat){
    newPasswordRepeat.addEventListener("focusout", validatePasswords);
  }
  if(newPassword){
    newPassword.addEventListener("focusout", validatePasswords);
  }
}

forgotPassword();
