const foldoutList = document.querySelectorAll(".foldout-item");
var duration = 500;

if (foldoutList) {
  foldoutList.forEach((element) => {
    var heading = element.querySelector(".foldout-item-heading");
    var text = element.querySelector(".foldout-item-text");
    var textInner = element.querySelector(".foldout-item-text-inner");

    heading.addEventListener("click", function () {
      if (element.classList.contains("expanded")) {
        var startHeight = text.offsetHeight;
        text.style.height = startHeight + "px";
        element.classList.remove("marked");
        setTimeout(function () {
          text.classList.add("animating");
          text.style.height = "0";
          setTimeout(function () {
            element.classList.remove("expanded");
            text.classList.remove("animating");
            text.removeAttribute("style");
          }, duration);
        });
      } else {
        var desiredHeight = textInner.offsetHeight;
        text.classList.add("animating");
        text.style.height = desiredHeight + "px";
        element.classList.add("marked");
        setTimeout(function () {
          element.classList.add("expanded");
          text.classList.remove("animating");
          text.removeAttribute("style");
        }, duration);
      }
    });
  });
}
